<!--
 * @Descripttion:
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-08 15:12:38
-->
<template>
    <div>
        <div class="pad-row">
            <div class="whiteBg pad-row tt">
                <p><span>项目编号</span><span>{{project.projectNum}}</span></p>
                <p><span>项目名称</span><span>{{project.projectName}}</span></p>
                <p><span>采购单位</span><span>{{project.purchaseOrg}}</span></p>
                <p><span>评审时间</span><span>{{project.reviewDatetime}}</span></p>
                <p><span>评审地点</span><span>{{project.reviewSite}}</span></p>
            </div>
            <div class="whiteBg pad-row mar-t2 tt">
                <p><span>包组编号</span><span>{{project.packageNum}}</span></p>
                <p><span>专家人数</span><span>{{project.expertNum}}</span></p>
                <p>
                  <span>评审专业</span>
                </p>
            </div>
            <div class="whiteBg" style="padding:0.2rem 0.3rem">
              <ul class="w-100">
                <li class="center van-ellipsis greenLi fw" v-for="(item,index) in selectedMajors">
                  <p>{{ item.fullName }}</p>
                </li>
              </ul>
            </div>
            <div class="whiteBg mar-t2 pad25" v-for="(item,index) in rounds" :key="index+'round'">
				<div class="flex-between">
					<p style="color:#A6A6A6" class="f3">第{{item.roundNum}}轮专家</p>
				</div>
				<div class="flex-item" v-for="(expertItem,index) in item.experts" :key="index+'expert'">
					<p class="fc fw w15">{{expertItem.name}}</p>
					<p class="c4">{{expertItem.mobile}}</p>

					<p class="gray2 w15 flex-between" v-if="expertItem.confirm=='0'">
						<span class="f35">请确认</span>
						<i class="icon-arrow wh06"></i>
					</p>
					<p class="gray2 w15 flex-between" v-else-if="expertItem.confirm=='1'">
						<span class="f35">参与</span>
					</p>
					<p class="gray2 w15 flex-between" v-else-if="expertItem.confirm=='2'">
						<span class="f35">不参与</span>
					</p>
				</div>

            </div>
            <p class=" f35 mar-t2 gray2">请在电脑端项目记录中打印</p>
			<div class="h2"></div>
        </div>
        <div class="fixed-bottom whiteBg w-100 pad25">
            <button @click="finish()">完成</button>
        </div>
    </div>
</template>

<script>
import {Dialog} from 'vant'
export default {
	data(){
		return {
			project:{},
			rounds:[],
			selectedMajors:[]
		}
	},
    beforeMount(){
        this.$emit('onActive',3)
    },
	created() {
		this.project = this.$ls.get('demo-project') || {}
        if(!this.project.projectName){
            this.$dialog.alert({
                title: '错误',
                message: '未获取到项目信息'
            }).then(()=>{
                this.$ls.remove('major-demo-list')
                this.$ls.remove('rounds-demo')
                this.$ls.remove('confirm-expert-list-demo')
                this.$router.push('/demo-1')
            })
        }else{
            this.rounds = this.$ls.get('rounds-demo')
            this.selectedMajors = this.$ls.get('major-demo-list')
        }

	},

    components:{
        [Dialog.name]:Dialog
    },

	methods:{
		finish:function(){
            /** 清除Demo中的所有缓存 **/
            this.$ls.remove('major-demo-list')
            this.$ls.remove('demo-project')
            this.$ls.remove('rounds-demo')
            this.$ls.remove('confirm-expert-list-demo')
            this.$router.push({name:'demo-1'})
		}
	}
}
</script>

<style scoped>
.tt>p{display: table-row;line-height: 0.8rem;}
    .tt>p>span{display: table-cell; font-weight: 500; padding: 0.2rem 0;}
    .tt>p>span:first-child{width: 2rem;}
    .tt>p>span:last-child{color: #737373;}
    .c4{width: calc(100% - 3.5rem);}
    .flex-item{padding-top: 0.2rem;}
    .fixed-bottom>button{width: 100%; padding: 0.15rem 0.1rem; text-align: center; background-color: #00CCB2; font-weight: bold; color: white; border-radius: 0.5rem;}
    .w15{width: 1.7rem;}
    .greenLi:not(:first-child){margin-top: 0.2rem;}
</style>